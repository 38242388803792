import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import SwiperCore, {
  Navigation,
  EffectFade,
  Pagination,
  Lazy,
  Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SliderStyled from '../../styles/SliderStyles.style';
// import 'swiper/swiper.scss';
// import 'swiper/components/effect-fade/effect-fade.scss';

SwiperCore.use([Navigation, EffectFade, Pagination, Lazy, Autoplay]);

// const ImageBannerStyle = styled.div`
//   background-color: red;
//   .gatsby-image-wrapper-constrained {
//     display: block;
//     @media screen and (min-width: 1536px) {
//       /* min-height: calc(100% / 2.84); */
//       height: 526.7px;
//     }
//   }
// `;

const Banner = React.memo(
  ({ banners, navigationW, navigationH, iconSize, kind }) => (
    <SliderStyled
      navigationW={navigationW}
      navigationH={navigationH}
      iconSize={iconSize}
      kind={kind}
    >
      <Swiper
        lazy={true}
        effect={'cube'}
        loop={true}
        autoplay={{
          delay: 4000,
        }}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        preloadImages={true}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <GatsbyImage image={banner.asset.gatsbyImageData} alt="banner" />
            <div className="swiper-lazy-preloader" />
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderStyled>
  )
);

export default Banner;
