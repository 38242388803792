import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import RichText from '../components/RichText/RichText.component';
import Seo from '../components/Seo.component';
import Banner from '../components/Banner/Banner.component';

// import useLatestData from '../utils/useLatestData';

// function Goods({ goods }) {
//   return (
//     <div>
//       <h2 className="center">
//         <span className="mark tilt">Sản phẩm</span>
//       </h2>
//       <p>Đặt hàng ngay để nhận ưu đãi!</p>
//       {goods?.length && <ItemGrid items={goods} type="arr" kind="product" />}
//     </div>
//   );
// }

function Homepage() {
  const {
    content: { nodes: description },
    banner: { image: banner },
    sanityStoreSettings: { goods, certificate: certificates },
  } = useStaticQuery(graphql`
    query {
      banner: sanityBanner(name: { eq: "homepage" }) {
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        name
      }
      sanityStoreSettings(name: { eq: "241NVT" }) {
        certificate {
          id
          name
          imageAward {
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
          }
          slug {
            current
          }
          shortdesc
        }
        goods {
          id
          name
          slug {
            current
          }
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
            }
          }
        }
      }
      content: allSanityStoreSettings {
        nodes {
          description {
            _type
            children {
              _key
              _type
              text
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Seo title="Trang chủ" />
      <main className="container p-5 mx-auto">
        <Banner banners={banner} kind="banner" />

        <div className="py-10 mx-auto sm:text-center lg:max-w-3xl">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-3xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-1 mb-4 text-base font-semibold tracking-wider uppercase rounded-full bg-primary_light">
                CÔNG TY
              </p>
            </div>
            <h2 className="max-w-lg mb-6 text-2xl font-bold leading-none tracking-tight text-gray-900 font-header sm:text-3xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95)"
                    width="52"
                    height="24"
                  />
                </svg>
              </span>{' '}
              TNHH MTV TM DV VT Nhã Thy
            </h2>
            <div className="max-w-3xl text-base sm:mx-auto">
              <RichText rText={description[0].description} />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          <div className="text-center">
            <h2 className="text-2xl font-bold leading-none tracking-tight text-gray-900 sm:text-3xl">
              Giải thưởng
            </h2>
            <p className="py-3 text-base">Các thành tích đã đạt được:</p>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
            <div className="grid grid-cols-2 gap-6 row-gap-5 mb-8 sm:row-gap-6">
              {certificates &&
                certificates?.map((item) => (
                  <Link key={item.id} to={`award/${item.slug.current}`}>
                    <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl max-h-80">
                      <GatsbyImage
                        image={item.imageAward.asset.gatsbyImageData}
                        alt={item.name}
                      />
                      <div className="absolute inset-x-0 bottom-0 px-6 py-4 bg-black bg-opacity-60">
                        <p className="text-sm font-medium tracking-wide text-center text-white">
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
            <div className="text-center">
              <Link
                to="/awards"
                className="inline-flex items-center px-3 py-1 transition-colors duration-300 border rounded hover:text-primary border-primary"
              >
                Chi tiết
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="text-center">
            <h2 className="text-2xl font-bold leading-none tracking-tight text-gray-900 sm:text-3xl">
              Sản phẩm bán chạy
            </h2>
            <p className="py-3 text-base">Đặt hàng ngay để nhận ưu đãi!</p>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />

            <div className="grid grid-cols-2 gap-6 row-gap-5 mb-8 sm:row-gap-6">
              {goods &&
                goods?.map((item) => (
                  <Link key={item.id} to={`product/${item.slug.current}`}>
                    <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl max-h-80">
                      <GatsbyImage
                        image={item.image[0].asset.gatsbyImageData}
                        alt={item.name}
                      />

                      <div className="absolute inset-x-0 bottom-0 px-6 py-4 bg-black bg-opacity-60">
                        <p className="text-sm font-medium tracking-wide text-center text-white">
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
            <div className="text-center">
              <Link
                to="/products"
                aria-label=""
                className="inline-flex items-center px-3 py-1 transition-colors duration-300 border rounded hover:text-primary border-primary"
              >
                Chi tiết
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Homepage;
