import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import urlBuilder from '@sanity/image-url';
const urlFor = (source) =>
  urlBuilder({ projectId: 'v3xfey4o', dataset: 'production' }).image(source);
const serializer = {
  types: {
    inlineImage: (props) => {
      return (
        <figure>
          <img
            src={urlFor(props.node.asset).url()}
            alt={props.node.alt || 'Hình ảnh'}
          />

          {props.node.caption && <figcaption>{props.node.caption}</figcaption>}
        </figure>
      );
    },
    richText: (props) => {
      return <p>{props.children}</p>;
    },
  },
};

export default function RichText({ rText }) {
  // debugger;
  if (!rText) return null;
  return (
    <BlockContent
      className="prose prose-xl"
      blocks={rText}
      serializers={serializer}
    />
  );
}
